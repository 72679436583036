import { createStore } from 'vuex'
import { jwtDecode } from "jwt-decode";

import modules from './modules'

export default createStore({ 
    state () {
        return {
            token: null,

            context: Object(),
            tokenDecoded: Object(),
            refreshToken: Object()
        }
    },

    mutations: {
        setContext(state, context) {
            state.context = context
        },
        setToken(state, token) {
            state.token = token

            if(token) {
                const decoded = jwtDecode(token);
                state.tokenDecoded = decoded
            } else {
                state.tokenDecoded = Object()
            }
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken
        }
    },

    actions: {
        
    },

    modules
})