import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export default {
  install: (app, options) => {
    const instance = axios.create({
      baseURL: options.baseURL || '',
      timeout: options.timeout || 10000,
    });

    instance.interceptors.request.use(async (config) => {
      const store = app.config.globalProperties.$store
      const cookies = app.config.globalProperties.$cookies;

      var token = store.state.token ? store.state.token : cookies.get('bearer')
      var tokenDecoded = null
      var refreshTokenValidity = null

      if(token) {
        tokenDecoded = jwtDecode(token);
        refreshTokenValidity = cookies.get('refreshTokenValidUntil') ? parseInt(cookies.get('refreshTokenValidUntil')) : null
      }

      if(tokenDecoded && tokenDecoded.exp * 1000 < Date.now() && !config.url.endsWith('/token/refresh') && refreshTokenValidity > Date.now()) {
        const refreshTokenId = store.state.refreshToken && store.state.refreshToken.id ? store.state.refreshToken.id : cookies.get('refreshTokenId');
        const refreshTokenValue = store.state.refreshToken && store.state.refreshToken.value ? store.state.refreshToken.value : cookies.get('refreshToken');

        try {
          const response = await axios.get(process.env.VUE_APP_COMMERCE_API_URL + '/token/refresh', {
            params: {
              oldToken: token,
              refreshToken: refreshTokenValue,
              refreshTokenId: refreshTokenId
            }
          });

          // const expireTimes = 60 * 60 * 24 * process.env.VUE_APP_COOKIE_EXPIRES

          cookies.set('bearer', response.data.token);
          cookies.set('refreshTokenId', response.data.refreshTokenId);
          cookies.set('refreshToken', response.data.refreshToken);
          cookies.set('refreshTokenValidUntil', response.data.refreshTokenValidUntil);

          token = response.data.token;
          tokenDecoded = jwtDecode(token);

          store.commit('setToken', response.data.token);
          store.commit('setRefreshToken', {
            validity: response.data.refreshTokenValidUntil,
            value: response.data.refreshToken,
            id: response.data.refreshTokenId
          });
        } catch (error) {
          console.log('error req token', error);
        }
      } else if(token && refreshTokenValidity < Date.now()) {
        cookies.set('bearer', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');
        cookies.set('refreshTokenId', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');
        cookies.set('refreshToken', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');
        cookies.set('refreshTokenValidUntil', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');

        store.commit('setToken', null);
      }

      if (tokenDecoded && tokenDecoded.exp * 1000 > Date.now()) {
        config.headers['Authorization'] = 'Bearer ' + token
      }

      return config;
    });

    app.config.globalProperties.$axios = instance;
  }
};