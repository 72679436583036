<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card theme="dark" rounded="lg">
                <v-card-title>
                       <div class="mt-2 text-h5 text-medium-emphasis ps-2">Logout dari Tuneeca</div>
                </v-card-title>
                <v-card-text>
                    <div>
                        Yakin akan keluar dari aplikasi?
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false">
                        Batal
                    </v-btn>
                    <v-btn @click="logout()" :loading="isLoading" color="error">
                        Keluar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

export default {
    name: "Dialog Logout",

    data: () => {
        return {
            dialog: false,
            isLoading: false,
        }
    },

    methods: {
        showDialog() {
            this.dialog = true
        },

        async logout() {
            try {
                this.isLoading = true
    
                await this.$axios.put("/users/expire", {
                    jwt: this.$cookies.get("bearer"),
                    refreshTokenId: this.$cookies.get("refreshTokenId"),
                    refreshToken: this.$cookies.get("refreshToken"),
                });

                this.setCookie()
    
                this.isLoading = false
                this.dialog = false
                this.$toast.success('Berhasil melakukan logout')
            } catch (error) {
                this.isLoading = false
                if(error.response) {
                    if(error.response.status == 404) {
                        this.setCookie()
                        this.dialog = false
                    } else {
                        this.$toast.success(error.response.data.message)
                    }
                }
                console.log("error-logout", error)
            }
        },

        setCookie: function () {
            this.$cookies.set('bearer', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');
            this.$cookies.set('refreshTokenId', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');
            this.$cookies.set('refreshToken', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');
            this.$cookies.set('refreshTokenValidUntil', '', 'Thu, 01 Jan 1970 00:00:01 GMT;');

            this.$store.commit('setToken', null);
        },
    }
}
</script>

<style>
.bg-primary{
    background-color:#694177 !important;
}

.btn-primary{
    background-color:#694177 !important;
}
</style>
